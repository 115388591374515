// import modules that define the main functionalities of the app
import Autocompleter from './modules/autocompleter';
import Clicks from './modules/clicks';
import Ratings from './modules/ratings';

export default class App {
  /**
   * Autocompleter module instance
   *
   * @type {Autocompleter}
   */
  autocompleter;

  /**
   * Clicks module instance
   *
   * @type {Clicks}
   */
  clicks;

  /**
   * Ratings module instance
   *
   * @type {Ratings}
   */
  ratings;

  constructor(options = {}) {
    this.options = Object.assign(
      {},
      {
        // define current environment (defined in environment variable as: development, staging, prod)
        environment: inbenta.seo.env.INBENTA_ENV,
      },
      options,
    );
  }

  initialize() {
    this.autocompleter = new Autocompleter();
    this.clicks = new Clicks();
    this.ratings = new Ratings();

    // ============================== Search form reset button =================================
    var searchFormNode = document.querySelector('.inbenta-km__search__form');
    var searchInputNode = document.querySelector('.inbenta-km__search__form .inbenta-km-input');

    // build reset search button
    const searchResetButton = searchFormNode.querySelector('.inbenta-km__search__form .form__reset');
    searchResetButton.addEventListener('click', () => {
      searchInputNode.value = '';
      searchResetButton.classList.remove('active');
      searchInputNode.focus({ preventScroll: true });
    });

    searchInputNode.addEventListener('input', () => {
      if (searchInputNode.value !== '') {
        searchResetButton.classList.add('active');
      } else {
        searchResetButton.classList.remove('active');
      }
    });


    // ============================== scrollTo on body =================================
    const bodyScrollTo = document.querySelectorAll('body[data-scroll]');
    if (bodyScrollTo.length > 0) {
      const target = bodyScrollTo[0].getAttribute('data-scroll');
      const targetElement = document.querySelector('#'+target);
      const header = document.querySelector('.inbenta-helpsite__header');
      const topPos = targetElement.getBoundingClientRect().top - header.getBoundingClientRect().height + window.pageYOffset;

      window.scrollTo({
        top: topPos,
        behavior: 'smooth' // smooth scroll
      })
    }


    // ============================== Event Listeners for contents =================================
    var categoriesTitles = document.querySelectorAll('.container__category__title');
    for (var contentIndex = 0; contentIndex < categoriesTitles.length; ++contentIndex) {
      var contentSection = categoriesTitles[contentIndex];
      contentSection.addEventListener('click', function (event) {
        var hasSubcatgeories = event.currentTarget.closest('.container__category').querySelector('.container__sub-category');
        if (hasSubcatgeories !== null) {
          event.preventDefault();
          var isActive = (event.currentTarget.closest('.container__category').classList.contains('container__category--active'));
          if (event.target.closest('.inbenta-km__categories--sidebar').querySelector('.container__category--active')) {
            event.target.closest('.inbenta-km__categories--sidebar').querySelector('.container__category--active').classList.remove('container__category--active');
          }

          if (!isActive) {
            event.target.closest('.container__category').classList.add('container__category--active');
          }
        }
      });
    }
  }
}
