/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

import { post } from '../helpers/request';
import { rate } from '../helpers/tracking';

/**
 * Ratings class used to handle all ratings element within the website.
 */
export default class Ratings {
  constructor(options = {}) {
    // init options
    this.options = Object.assign(
      {},
      {
        selectors: {
          // main rating container
          container: '.inbenta-km__rating',
          // buttons wrapper list
          buttonsContainer: '.inbenta-km__rating__content',
          // button wrapper
          buttonWrapper: '.content__buttons__button-wrapper',
          // rating data elements
          ratingData: '.rating-data',
          // rating button
          button: '.inbenta-km-button',
        }
      },
      options,
    );
    // init listener
    this.#init();
  }

  /**
   * Initialize listeners for rating elements
   */
  #init() {
    const { selectors } = this.options;
    // get all ratings container
    const ratingContainers = document.querySelectorAll(selectors.container);

    // iterate each ratings container
    ratingContainers.forEach((container) => {
      // find the buttons container
      const buttonsContainer = container.querySelector(selectors.buttonsContainer);
      // find each rating buttons wrapper in the current container
      const buttons = buttonsContainer.querySelectorAll(selectors.buttonWrapper);
      // iterate each button to add 'click' event
      buttons.forEach((wrapper) => {
        // get rating data
        const ratingData = wrapper.querySelectorAll(selectors.ratingData);
        // get button and add event listener
        const button = wrapper.querySelector(selectors.button);
        button.addEventListener('click', () => {
          this.processRatingNextStep(container, buttonsContainer, ratingData);
        })
      })
    })
  }

  /**
   * Generate a element for rating modal
   */
  createElement (type, attributes = {}, content = '', callback = null) {
    const domElement = document.createElement(type);
    Object.keys(attributes).forEach((name) => {
      domElement.setAttribute(name, attributes[name]);
    });
    if (content !== '') {
      const domContent = document.createTextNode(content);
      domElement.appendChild(domContent);
    }
    return (callback !== null) ? callback(domElement) : domElement;
  }

  /**
   * Generate a choice of the rating modal
   */
  createFormItem (value, label) {
    const mainDiv = this.createElement('div',
      { class: 'inbenta-km-inside-modal-rating-form-item' });
    mainDiv.appendChild(this.createElement('input',
      {
        name: 'rating',
        value,
        'data-label': label,
        id: `rating_${value}`,
        type: 'radio'
      }));
    mainDiv.appendChild(this.createElement('label', {
      for: `rating_${value}`,
    }, label));
    return mainDiv;
  }

  /**
   * Fill the rating modal on click ( specific demand of the client that the HTML is generated onclick, for when the JS is disabled )
   */
  buildRatingModal () {
    const modalTitle = document.querySelector('.bpce-header-ellipsis-container');
    modalTitle.innerHTML = '';
    modalTitle.appendChild(this.createElement('div', {}, 'Vous n\'avez-pas trouvé ?'));

    const modalbody = document.querySelector('.inbenta-km-inside-modal-rating-view');
    modalbody.innerHTML = '';
    modalbody.appendChild(
      this.createElement('p',
        { class: 'inbenta-km-inside-modal-rating-title' },
        'Pourquoi cette réponse n\'est pas satisfaisante ?')
    );

    const formElement = this.createElement('form',
      { id: 'inbenta-km-inside-modal-rating-form' });
    formElement.appendChild(this.createFormItem('5', 'Ma question n\'a pas été comprise'));
    formElement.appendChild(this.createFormItem('6', 'La réponse n\'est pas claire'));
    formElement.appendChild(this.createFormItem('7', 'La solution proposée ne me satisfait pas'));
    modalbody.appendChild(formElement);
    modalbody.appendChild(
      this.createElement('a',
        { class: 'inbenta-km-button' },
        'Envoyer')
    );
  }

  /**
   * Process to the next step
   *
   * @param {HTMLElement} container Rating container
   * @param {HTMLElement} buttonsContainer Buttons container
   * @param {NodeListOf<Element>|NodeListOf<HTMLElement>} ratingData Rating data clicked
   */
  processRatingNextStep(container, buttonsContainer, ratingData) {
    buttonsContainer.classList.toggle('inbenta-km__rating__content--hidden');
    if (ratingData[2].value === '1') {
      this.goToCommentStep(container, ratingData);
    } else {
      this.goToThanksStep(container, ratingData);
    }
  }

  /**
   * Function to send tracking event on rating
   *
   * @param {string} trackingCode
   * @param {string} value
   * @param {?string} comment
   *
   * @return {Promise<any>|void}
   */
  sendTracking(trackingCode, value, comment = null) {
    return rate(trackingCode, value, comment);
  };

  /**
   * Function to display rating comment step
   *
   * @param {HTMLElement} container Rating container
   * @param {NodeListOf<Element>|NodeListOf<HTMLElement>} ratingData Current rating data
   */
  goToCommentStep(container, ratingData) {
    this.buildRatingModal();

    const commentSection = container.querySelector('.inbenta-km__rating__comment');
    const commentButton = commentSection.querySelector('.inbenta-km-button');

    commentSection.classList.toggle('inbenta-km__rating__comment--hidden');

    // We send a first tracking event to log value without comment.
    this.sendTracking(ratingData[1].value, ratingData[0].value);

    commentButton.addEventListener('click', () => {
      const inputs = document.querySelectorAll('#inbenta-km-inside-modal-rating-form input[name=rating]:checked');
      // If a choice has been selected
      if (inputs.length > 0) {
        commentSection.classList.toggle('inbenta-km__rating__comment--hidden');
        const commentInput = inputs[0].value;
        this.goToThanksStep(container, ratingData, commentInput);
      }
    });
  }

  /**
   * Function to display rating thanks step
   *
   * @param {HTMLElement} container Rating container
   * @param {NodeListOf<Element>|NodeListOf<HTMLElement>} ratingData Current rating data
   * @param {?string} comment Comment to send
   */
  goToThanksStep(container, ratingData, comment = null) {
    const thanksSection = container.querySelector('.inbenta-km__rating__thanks');
    thanksSection.classList.toggle('inbenta-km__rating__thanks--hidden');
    this.sendTracking(ratingData[1].value, ratingData[0].value, comment);
  }
}
